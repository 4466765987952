import React, {Component} from 'react';
import './CoffeeCup.css';

class CoffeeCup extends Component {

    render() {
        return (
            <svg version="1.1" id="coffee_cup" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 432 432">
                <g>
                    <g>
                        <path class="st2" d="M203.7,176.6c2,0.8,3.7,1.4,5.5,2.1c0.3,0.1,0.7,0,1,0c2.3-0.4,4.6-1.3,6.7-2.2c7.1-3.2,13.5-7.4,19.5-12.3
        c1.9-1.5,3.7-3.2,5.5-4.8c0.6-0.5,0.8-1,0.8-1.8c0.1-4.9,0.2-9.8,0.5-14.6c0.7-11,2-22,4.6-32.7c2.3-9.3,5.1-18.5,9.3-27.2
        c1.6-3.3,3.4-6.6,5.6-9.5c1.9-2.6,4.1-4.8,6.7-6.5c2.3-1.4,4.7-2.3,7.4-2.2c3.1,0.2,5.6,1.5,7.7,3.6c2.4,2.4,4,5.3,5,8.5
        c1.2,3.7,1.5,7.4,1.3,11.3c-0.5,10.2-3.9,19.4-8.9,28.1c-4.1,7.3-9,14.1-14.1,20.7c-4.4,5.7-9,11.2-13.9,16.6
        c-0.4,0.5-0.7,0.9-0.6,1.6c0.1,4.9,0.1,9.9,0.2,14.8c0,2.2,0.2,4.4,0.3,6.6c0,0.4,0.1,0.9,0.1,1.4c0.8,0.2,1.5,0.2,2.2,0
        c1.9-0.5,3.6-1.6,5.1-2.8c1.4-1.1,2.6-2.4,3.7-3.8c1.7-2.3,3.1-4.8,4.4-7.3c0.2-0.4,0.2-0.9,0.2-1.3c-0.1-2.8-0.2-5.7,0.2-8.5
        c0.8-6.3,3.2-11.9,8-16.3c4-3.7,8.8-4.6,13.9-2.7c4.2,1.6,6.7,5.8,6.4,10.3c-0.2,3.3-1.5,6.1-3.8,8.5c-3.4,3.5-7.6,5.7-12.4,6.5
        c-0.5,0.1-1.1,0.2-1.8,0.3c0.1,1.2,0.2,2.3,0.4,3.4c0.5,3.7,1.3,7.3,2.7,10.7c1.3,3.3,3.3,6.1,6.1,8.3c1,0.8,2.1,1.4,3.4,1.8
        c1.1,0.3,2.1,0.2,3.1-0.3c1.2-0.6,2.2-1.4,3.2-2.4c1.8-1.9,3.1-4,4.3-6.3c2.5-4.8,4.7-9.7,6.8-14.7c0.4-1,1-1.9,1.1-2.9
        c0.1-0.9-0.5-1.9-0.8-2.8c-1.5-5-2.1-10.1-1.8-15.3c0.3-5.9,1.7-11.6,4.4-16.9c1.3-2.5,2.9-4.9,4.9-6.9c5.9-6,15.1-5,19.6,2
        c2,3.1,2.7,6.6,2.4,10.3c-0.6,9.4-7.2,17.2-16.3,19.4c-1.1,0.3-2.3,0.4-3.6,0.6c0,0.5-0.2,1-0.1,1.4c0.8,4.3,2.2,8.4,4.1,12.4
        c1.2,2.4,2.7,4.6,4.5,6.5c2.4,2.6,5,3.1,8.2,1.4c2-1,3.6-2.5,5.1-4.2c2.6-2.9,4.6-6.2,6.2-9.8c1.2-2.7,2.3-5.5,3.4-8.3
        c0.3-0.7,0.6-1.4,1.1-2c0.8-1.1,2.3-1.5,3.6-1c0.9,0.3,1.3,0.9,1.2,1.9c0,0.5-0.1,1-0.3,1.5c-1.5,3.8-2.9,7.7-4.6,11.5
        c-2.1,4.7-5.1,8.7-9.1,12c-3,2.6-6.5,4.2-10.5,4.4c-4.8,0.3-8.8-1.3-12.4-4.4c-2.1-1.8-3.9-4-5.4-6.3c-0.2-0.3-0.5-0.7-0.8-1.1
        c-0.2,0.5-0.4,0.8-0.5,1.2c-1.1,2.8-2.2,5.7-3.4,8.5c-1.7,3.8-3.8,7.3-6.6,10.5c-1.8,2-3.9,3.7-6.5,4.7c-3.9,1.5-7.8,1.2-11.6-0.3
        c-5.7-2.3-9.8-6.4-12.5-11.9c-0.9-1.8-1.5-3.8-2.3-5.8c-0.1-0.3-0.2-0.7-0.4-1.3c-1.7,2.6-3.4,4.8-5.6,6.6
        c-2.2,1.8-4.6,3.2-7.4,4.2c0.4,0.4,0.7,0.6,1,0.9c3.6,3.1,7.3,6.1,10.7,9.3c5.3,4.9,10,10.4,14,16.4c4.3,6.5,7.1,13.7,8.3,21.4
        c0.7,4.3,0.7,8.7-0.4,13c-0.6,2.4-1.6,4.6-3.2,6.5c-3.4,4-8.4,5.3-13.3,3.4c-1.2-0.5-2.3-1.1-3.4-1.8c-3.1-2-5.8-4.5-8.2-7.4
        c-2.5-3.2-5-6.5-7-10.1c-3.4-6.3-5.9-13.1-7.7-20c-1.9-7.2-3.1-14.4-4.1-21.8c-1-7.9-1.6-15.9-1.8-23.9c0-0.8-0.1-1.6-0.1-2.6
        c-0.5,0.4-0.8,0.6-1.1,0.9c-6,4.9-12.3,9.4-19.4,12.8c-2.4,1.2-4.9,2.2-7.5,3.3c0.3,0.2,0.7,0.5,1,0.7
        c8.8,6.2,16.5,13.6,22.6,22.4c4.7,6.8,7.8,14.2,9.1,22.4c0.9,5.5,1,11-0.6,16.4c-0.5,1.7-1.2,3.4-2.2,4.9c-2.3,3.3-5.4,5-9.3,5.5
        c-3.8,0.5-7.4-0.4-10.9-1.9c-5.3-2.2-9.6-5.7-13.5-9.9c-6-6.5-9.8-14.2-12.4-22.5c-1.7-5.5-2.8-11.2-3.6-16.9
        c-0.8-5.6-1.2-11.3-1.6-17c-0.6-9.6-0.7-19.3-0.5-28.9c0-2.8,0.3-5.5,0.4-8.3c0-0.4,0-0.8,0-1.3c-2,0.8-3.9,1.5-6,2.3
        c0.1,1,0.1,2,0.2,3c0.3,9.2-0.9,18.2-4,26.9c-1,2.9-2.4,5.7-4.2,8.1c-2.1,2.8-4.8,4.8-8.2,5.8c-3.3,0.9-6.6,0.6-9.7-1
        c-6.9-3.5-11.1-9.2-12.9-16.7c-0.3-1.1-0.3-2.3-0.5-3.4c0-0.3-0.1-0.6-0.2-1c-0.3,0.4-0.5,0.7-0.7,1c-2.4,3.9-4.8,7.9-7.3,11.8
        c-1.7,2.7-3.6,5.2-5.7,7.5c-1.6,1.7-3.3,3.2-5.3,4.3c-6.4,3.8-13.7,2.8-18.9-2.4c-3.5-3.5-5.8-7.7-7.5-12.3
        c-1.5-4.1-2.4-8.3-2.9-12.6c-0.6-4.6-0.7-9.2-0.4-13.8c0.5-7.4,1.8-14.6,4.6-21.5c1-2.6,2.3-5,4.2-7c2.1-2.4,4.6-4.2,7.6-5.1
        c4.6-1.3,8.9-0.4,12.8,2.4c3.4,2.5,5.3,6,6,10.1c0.7,4.3,0.4,8.5-0.9,12.6c-0.2,0.6-0.4,1.2-0.7,1.7c-0.2,0.4-0.5,0.7-0.9,0.9
        c-0.6,0.4-1.3,0.4-1.9,0c-0.6-0.4-0.5-0.9-0.5-1.5c0.2-1.9,0.5-3.7,0.6-5.6c0.2-3.1,0-6.2-0.9-9.2c-0.6-2.2-1.8-4.1-3.4-5.6
        c-1-1-2.2-1.7-3.6-2c-1.5-0.4-2.9-0.2-4.3,0.6c-1.7,1.1-3,2.6-4,4.4c-1.5,2.8-2.4,5.9-3.1,9c-0.8,3.8-1.2,7.7-1.6,11.5
        c-0.3,3.3-0.5,6.7-0.4,10.1c0.3,7,1,13.8,3.3,20.5c1,3,2.4,5.8,4.3,8.3c0.6,0.8,1.4,1.7,2.2,2.3c1.9,1.5,4,1.9,6.3,0.8
        c1.4-0.7,2.7-1.6,3.8-2.6c2-1.7,3.7-3.7,5.2-5.8c2.7-3.9,5.3-7.8,7.9-11.7c2.1-3.2,4.2-6.4,6.3-9.7c0.4-0.5,0.6-1.2,0.8-1.8
        c0.6-1.7,1.1-3.4,2.2-4.9c1.1-1.5,2.5-2.3,4.4-2.2c1.9,0.1,2.9,1.2,3,3c0,1.1,0,2.3-0.1,3.4c-0.2,3.9-0.5,7.8-0.1,11.7
        c0.5,4.5,1.6,8.8,4.5,12.4c1.2,1.4,2.5,2.6,4.1,3.5c2.1,1.2,4.1,1,6-0.4c1.3-1,2.2-2.2,3-3.5c1.5-2.5,2.5-5.2,3.4-7.9
        c2.2-7.1,3.2-14.4,3-21.9c0-1-0.1-2.1-0.1-3.1c0-0.1,0-0.1-0.1-0.3c-0.5,0-0.9,0.1-1.4,0.1c-4.6,0.6-9.3,0.7-13.9-0.3
        c-5.7-1.3-10.2-4.4-13.1-9.6c-2.5-4.4-2.9-9-0.6-13.6c2-4,5.2-6.7,9.8-7.3c3-0.4,5.8,0.1,8.5,1.3c2.5,1.2,4.7,2.9,6.6,5
        c3.9,4.3,6.4,9.4,7.9,14.9c0.4,1.4,0.7,2.9,1.1,4.3c0.3-0.1,0.6-0.1,0.9-0.2c1.8-0.7,3.5-1.5,5.3-2.2c0.5-0.2,0.8-0.5,0.8-1.2
        c0.3-2.9,0.6-5.7,1-8.6c2.3-16.9,6.9-33.1,15.1-48.2c2.3-4.2,4.9-8.2,8.2-11.6c1.6-1.7,3.3-3.2,5.2-4.5c2.6-1.7,5.4-2.7,8.5-2.4
        c4.6,0.4,7.9,2.6,10,6.7c1,1.9,1.4,4,1.5,6.1c0.4,5.5-0.8,10.6-2.8,15.7c-1.6,4.1-3.8,8-6.1,11.8c-5.4,8.7-11.5,16.8-18.8,24.1
        c-3.2,3.2-6.5,6.1-10.2,8.7c-0.6,0.4-0.9,0.9-0.9,1.6c-1.1,10.5-1,21.1-0.9,31.7C203.7,174.3,203.7,175.5,203.7,176.6z M204.1,183
        c0,0.6,0,0.9,0,1.2c0.4,5,0.8,9.9,1.3,14.9c0.6,6,1.7,11.8,3.3,17.6c1.8,6.4,4.1,12.5,7.7,18.1c3,4.7,6.8,8.8,11.6,11.7
        c1.7,1,3.4,1.8,5.4,2.3c2.3,0.5,4.1-0.2,5.5-2.1c0.9-1.2,1.4-2.6,1.8-4c1.3-4.6,1.3-9.4,0.6-14.1c-1.3-8.4-4.5-16-9.3-23
        c-4.3-6.1-9.5-11.5-15.2-16.4c-2.5-2.2-5.2-4.2-8.1-5.8c-0.3-0.2-0.7-0.4-1-0.4C206.5,183,205.3,183,204.1,183z M253.8,146
        c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.1-0.3,0.2-0.4c7.2-8.7,14.1-17.6,20.1-27.2c3.5-5.6,6.7-11.5,8.8-17.8c2-6.1,3.3-12.3,2.5-18.8
        c-0.3-2.9-1-5.7-2.4-8.2c-0.7-1.2-1.5-2.3-2.5-3.2c-1-0.8-2.1-1.1-3.3-0.8c-0.6,0.2-1.3,0.4-1.8,0.7c-1.4,0.7-2.6,1.8-3.6,3
        c-1.4,1.6-2.5,3.5-3.5,5.4c-2.3,4.3-4.1,8.9-5.6,13.6c-2.2,7.1-3.9,14.4-5.3,21.7c-1.2,6.1-2.1,12.2-2.7,18.4
        c-0.3,2.9-0.5,5.8-0.8,8.7C253.9,142.9,253.9,144.4,253.8,146z M254.9,185.5c0,0.2-0.1,0.3-0.1,0.4c0,0.5,0.1,1,0.1,1.4
        c0.7,6,1.6,11.9,2.8,17.8c1.4,6.9,3.1,13.7,5.7,20.2c2.8,7,6.3,13.6,12,18.8c0.8,0.8,1.6,1.5,2.5,2.2c0.7,0.5,1.4,0.9,2.2,1.3
        c1.4,0.6,2.3,0.4,3.3-0.8c0.8-0.9,1.3-1.9,1.6-3c0.8-2.8,1.1-5.6,1-8.4c-0.3-6.7-2-13-4.9-19.1c-2.9-6.1-6.9-11.5-11.4-16.6
        c-4.5-5-9.6-9.4-14.5-14C255.2,185.7,255.1,185.6,254.9,185.5z M205.3,134.7c0.3-0.1,0.4,0,0.4-0.1c0.8-0.7,1.7-1.4,2.5-2.2
        c9.4-9,17.2-19.3,23.8-30.4c2.1-3.6,4-7.3,5.2-11.4c1.2-4.1,1.9-8.3,1.2-12.6c-0.3-1.8-0.8-3.5-1.9-4.9c-1.1-1.4-2.6-2-4.3-1.5
        c-0.8,0.2-1.6,0.6-2.3,1c-1.7,0.9-3.1,2.3-4.4,3.8c-2.6,3-4.7,6.3-6.4,9.8c-2.6,5.2-4.6,10.5-6.5,16c-3.1,9.1-5.2,18.5-6.6,28
        C205.7,131.7,205.5,133.2,205.3,134.7z M181.6,147.1c0-0.4-0.1-0.8-0.1-1.1c-0.6-3.3-1.5-6.6-2.8-9.7c-1.5-3.4-3.4-6.5-6.1-9
        c-1.4-1.4-3.1-2.6-5-3.2c-2.4-0.8-4.8-0.7-6.8,1c-3.1,2.7-4.5,6.2-3.8,10.3c1,5.8,5.9,10.6,11.8,11.8c1.3,0.3,2.6,0.4,3.8,0.5
        C175.6,147.8,178.5,147.7,181.6,147.1z M319.6,145.8c1.3,0,2.3-0.3,3.4-0.8c7.6-3.2,13-11.3,11.4-20.9c-0.3-2-1.2-3.9-2.8-5.3
        c-1.4-1.3-2.8-1.4-4.3-0.2c-0.9,0.7-1.7,1.6-2.3,2.5c-1.4,1.8-2.2,3.9-2.9,6c-1.2,3.6-2,7.4-2.4,11.2
        C319.4,140.8,319.2,143.2,319.6,145.8z M280.1,157.4c0.4,0,0.7,0,0.9-0.1c4.5-0.8,8-3.2,10.5-7c1.2-1.8,1.5-3.8,1.3-5.8
        c-0.2-1.5-0.6-2.9-1.7-4c-1.3-1.4-3.1-1.6-4.6-0.6c-0.5,0.3-0.9,0.7-1.3,1.2c-1.2,1.2-2,2.6-2.7,4.2c-1.1,2.6-1.7,5.3-2,8
        C280.2,154.6,280.2,156,280.1,157.4z"/>
                        <path class="st2" d="M239.4,315.8c0.4-0.5,0.8-1,1-1.5c0.8-1.7,1.7-3.4,2.2-5.2c1.9-6.4,3.8-12.8,5.6-19.2c0.1-0.5,0.2-1,0.1-1.5
        c-0.2-4.7-0.1-9.3,0.3-14c0.2-2.4,0.7-4.8,1.4-7.1c0.6-1.9,2-2.9,3.9-3c1.8-0.1,3,0.7,3.5,2.4c0.4,1.5,0.8,3.1,0.9,4.7
        c0.2,2.9,0.3,5.8,0.4,8.6c0,7.1,0.5,14.2,1.2,21.2c0.7,6.7,1.7,13.4,3.9,19.8c0.9,2.7,1.9,5.3,3.5,7.6c0.5,0.8,1.2,1.6,1.8,2.4
        c0.2,0.3,0.5,0.5,0.8,0.8c1.4,1.2,2,1.2,3.1-0.2c0.9-1.2,1.6-2.5,2.3-3.8c2.7-5.6,4.7-11.5,6.1-17.5c1.1-4.9,2-9.9,3.1-14.9
        c0.2-0.7-0.1-1.1-0.6-1.6c-3.3-2.9-6.6-5.8-9.6-9.1c-2.8-3.2-5-6.7-6.3-10.7c-0.7-2.4-1-4.9-0.3-7.4c1.2-4.8,5.2-7.7,9.8-7.4
        c3.1,0.2,5.6,1.6,7.6,4.1c1.8,2.3,2.8,4.9,3.6,7.7c0.8,3,1.3,6,1.4,9.1c0.1,2,0.1,4,0.1,6c0,0.5,0.1,0.8,0.5,1.1
        c3.8,3.1,7.6,6.3,11.4,9.4c0.1,0.1,0.2,0.1,0.4,0.3c0.2-0.4,0.4-0.7,0.6-1.1c2.5-4.7,5.5-8.9,9.5-12.5c2.7-2.4,5.7-4.4,9.2-5.4
        c3-0.8,6.1-1,9,0.3c3.7,1.5,5.7,4.4,6.2,8.3c0.4,3.5-0.2,6.8-0.9,10.2c-0.2,1.1-1.1,1.7-2.2,2c-1.1,0.2-2.1,0-2.9-0.8
        c-0.6-0.6-0.5-1.4-0.4-2.1c0.2-1.5,0.5-3,0.7-4.5c0.2-1.8,0.2-3.6-0.3-5.4c-0.2-0.6-0.4-1.2-0.7-1.7c-1.2-2.1-3.3-3-5.7-2.5
        c-2,0.4-3.9,1.2-5.5,2.4c-2.8,2-5.2,4.4-7.2,7.1c-2.2,2.9-3.9,6-5.5,9.5c0.3,0.2,0.7,0.4,1,0.7c3,2.9,5.7,5.9,8.1,9.2
        c4.9,6.6,7.7,14,8.7,22.1c0.5,4.2,0.2,8.4-1.5,12.4c-2.1,5-5.6,8.7-10.7,10.5c-5.5,2-11.3-0.9-13.9-4.7c-1.6-2.4-2.5-5-3.1-7.8
        c-1.3-5.5-1.3-11-0.6-16.5c0.8-6.5,2.1-12.9,3.9-19.2c0-0.1,0.1-0.3,0.1-0.6c-3-2.8-6-5.6-9.2-8.6c-0.1,0.6-0.2,1-0.3,1.4
        c-1.1,6.5-2.3,12.9-4.5,19.1c-1.3,3.9-2.8,7.8-4.7,11.5c-0.7,1.3-1.5,2.5-2.4,3.7c-1.2,1.6-2.8,2.9-4.7,3.7
        c-2.7,1.1-5.3,1-7.9-0.5c-1.4-0.8-2.6-1.9-3.7-3.1c-2.7-3-4.6-6.5-6.2-10.2c-2.4-5.4-3.9-11.1-4.8-16.9c-0.3-1.5-0.5-3.1-0.7-4.8
        c-0.2,0.4-0.3,0.7-0.4,0.9c-0.9,3-1.7,6-2.7,9c-0.4,1.3-1,2.6-1.7,3.8c-1.1,2-2.6,3.5-4.8,4.2c-2.2,0.8-4.3,0.5-6.3-0.5
        c-1.9-1-3.5-2.4-4.9-4c-2.7-3.1-4.6-6.7-6-10.5c-1.7-4.8-2.7-9.8-3.4-14.9c0-0.1-0.1-0.2-0.1-0.3c-0.7,0.4-4.2,4.4-6.3,7.3
        c0.1,0.3,0.2,0.6,0.3,0.9c2.6,6.4,4.6,13,5.8,19.8c1.3,7.4,0.8,14.7-1.9,21.8c-1.4,3.6-3.6,6.8-6.5,9.4c-2.7,2.4-5.9,3.8-9.5,3.9
        c-5.2,0.1-9.4-2.1-12.4-6.3c-2.8-3.8-3.8-8.1-3.3-12.8c0.5-4.6,2.2-8.8,4.3-12.9c2.8-5.5,6.4-10.4,10.3-15.2
        c1.1-1.3,2.1-2.6,3.2-3.9c-1.3-4.6-11.4-23.8-13.9-26.3c-0.3,0.9-0.6,1.8-0.9,2.7c-2.1,6.2-4.6,12.2-7.9,17.9
        c-1.9,3.3-4.2,6.4-6.9,9c-2.9,2.8-6.3,4.6-10.3,4.9c-2.6,0.2-5-0.5-7.4-1.6c-3.2-1.6-5.6-4-7.8-6.7c-2.5-3.1-4.5-6.6-6.1-10.3
        c-0.2-0.4-0.3-0.7-0.5-1.2c-3.2,2-6.4,3.9-9.6,5.9c0.1,0.8,0.2,1.7,0.4,2.5c1.6,9.6,2.6,19.2,2.1,28.9c-0.4,7.7-1.8,15.2-5.2,22.2
        c-1.5,3.1-3.4,6-5.9,8.4c-3.6,3.5-7.5,6.6-12.4,8c-4.1,1.2-8.2,1.3-12.3,0c-3.4-1.1-5.9-3.3-8-6c-2.7-3.6-4.1-7.7-4.7-12
        c-1.5-11.2,1.8-21.1,9-29.7c4-4.8,8.8-8.8,13.9-12.5c3.7-2.7,7.6-5.2,11.5-7.6c0.3-0.2,0.5-0.4,0.9-0.6
        c-3.1-17-7.1-33.8-10.7-50.8c-0.2,0-0.3,0-0.4,0c-6.9,2.6-13.7,5.3-20.3,8.6c-4.8,2.5-9.4,5.3-13.4,8.9c-1.5,1.4-3,3-4.3,4.6
        c-1.2,1.4-2,3-2.4,4.8c-0.3,1.4-1.4,2.1-2.8,2c-1.3-0.1-2.3-1.2-2.3-2.5c0-0.9,0.2-1.8,0.5-2.6c0.7-2,1.8-3.7,3.1-5.3
        c2.8-3.6,6.2-6.4,9.9-9c5-3.5,10.4-6.3,16-8.7c4.7-2,9.4-3.8,14.1-5.7c0.4-0.2,0.7-0.3,1.2-0.5c0-0.3-0.1-0.5-0.1-0.8
        c-1-5.7-2.1-11.5-3.1-17.2c-0.4-2.3-0.5-4.6-0.3-7c0.2-1.5,0.7-2.8,2.1-3.5c2.1-1.1,4.6-0.5,6,1.5c1.1,1.6,1.7,3.4,2,5.2
        c1,4.9,1.9,9.8,2.9,14.7c0.2,1.1,0.4,2.2,0.7,3.3c0.2,0,0.3,0,0.5,0c5.7-2,11.2-4.3,16.5-7.3c4.4-2.5,8.4-5.4,11.9-9
        c2-2.1,3.8-4.3,4.9-7c1.2-2.9,1.8-5.9,1-9c-0.2-0.7-0.5-1.4-0.8-2.1c-1.2-2.4-3.9-3.1-5.7-2.7c-1.5,0.3-2.9,0.8-4.3,1.3
        c-0.7,0.2-1.4,0.6-2.2,0.9c-1.4,0.5-2.8-0.1-3.3-1.4c-0.6-1.3-0.1-2.7,1.3-3.4c2.7-1.3,5.5-2.5,8.5-2.8c3.6-0.3,8.2,1.1,10.6,5.8
        c1.8,3.6,2.1,7.3,1.2,11.2c-1,4.6-3.3,8.4-6.4,11.8c-4.3,4.6-9.3,8.3-14.8,11.3c-5.2,2.9-10.7,5.1-16.3,7.1
        c-0.4,0.1-0.8,0.3-1.3,0.5c3.5,16.2,7.4,32.3,10.5,48.8c3.1-1.8,6-3.6,9-5.4c-0.1-0.3-0.1-0.6-0.2-0.9c-1.9-8.2-2-16.5,0.2-24.7
        c0.7-2.5,1.6-4.8,2.9-7c1.3-2.1,2.8-3.9,4.9-5.2c4-2.6,8.6-2.1,11.9,1.4c2.8,2.9,4.1,6.5,4.2,10.5c0.2,5.6-1.6,10.5-4.9,15
        c-2.3,3-5,5.5-7.9,7.8c-0.7,0.5-0.8,1-0.6,1.8c1.1,4.2,2.7,8.3,4.9,12.1c1.3,2.2,2.8,4.2,4.8,5.9c2.5,2.1,4.3,2.2,7,0.4
        c1.8-1.2,3.2-2.7,4.6-4.3c2.9-3.6,5.1-7.7,6.9-12c2.5-5.7,4.4-11.6,6-17.6c0.2-0.7,0-1.2-0.3-1.7c-3.1-4.5-6.3-9-9.4-13.6
        c-2.7-4-5.3-8.2-7.3-12.7c-1.3-3-2.3-6-2.6-9.2c-0.4-4.3,0.4-8.4,2.6-12.1c2.8-4.5,7.4-6.2,12.4-4.7c3,0.9,5.4,2.6,7.3,4.9
        c4,4.8,6.6,10.2,7.2,16.5c0.3,3,0.3,5.9,0.1,8.9c-0.3,5.2-1,10.4-2.1,15.6c-0.1,0.7,0.1,1.1,0.4,1.6c4.6,6.3,8.8,13,12.6,19.8
        c1.6,2.9,3.1,5.9,4.6,8.8c0.2,0.4,0.4,0.7,0.6,1.3c0.3-0.4,0.4-0.7,0.7-0.9c2.2-2.4,4.3-4.9,6.5-7.3c0.4-0.5,0.3-0.9,0.3-1.4
        c0-2.2-0.2-4.4-0.1-6.6c0.1-2.5,0.4-5,1.3-7.4c0.3-0.9,0.8-1.8,1.3-2.5c1.2-1.6,2.8-2.3,4.9-1.9c1.2,0.2,1.9,0.9,2.2,2.1
        c0.2,0.7,0.3,1.5,0.4,2.3c0.1,2.1,0.2,4.3,0.2,6.4c0.2,6.3,0.6,12.6,1.4,18.9c0.6,4.7,1.6,9.3,3.5,13.7c0.7,1.8,1.6,3.5,2.7,5
        C237.3,314.4,238,315.4,239.4,315.8z M121.9,302.3c-0.5,0.3-0.8,0.5-1.2,0.7c-3.6,2.5-7.3,4.9-10.8,7.5c-3.6,2.6-6.9,5.6-9.8,8.9
        c-5.2,5.8-8.6,12.3-9.3,20.1c-0.4,4.4,0,8.6,1.5,12.8c1.1,2.9,2.6,5.4,5.1,7.4c3,2.3,6.3,3,10,2.4c3-0.6,5.5-2.2,7.7-4.3
        c3.9-3.7,6-8.3,7.3-13.4c1-4.1,1.3-8.3,1.5-12.5c0.4-8.5-0.1-17-1.3-25.5C122.3,305,122.1,303.7,121.9,302.3z M205.3,307.1
        c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.1-0.1,0.3-0.2,0.4c-2.7,3.4-5.3,6.8-7.6,10.5c-2.3,3.7-4.3,7.6-5.5,11.9c-0.8,3-1.2,6.1-0.3,9.2
        c1.2,4.1,3.8,7,8.1,7.9c2.7,0.6,4.4-0.1,5.9-2.3c0.6-0.9,1.1-1.8,1.5-2.7c2.2-5.1,3-10.4,2.5-15.9c-0.3-3.5-0.9-6.9-1.7-10.3
        C206.9,312.9,206.1,310,205.3,307.1z M302.6,311.6c-0.2,0.5-0.3,0.9-0.3,1.2c-1.2,4.9-2.1,9.8-2.6,14.8c-0.4,4.1-0.4,8.2,0.4,12.2
        c0.5,2.3,1.2,4.6,2.4,6.6c0.9,1.4,2.1,2.6,3.7,3.2c3.1,1.1,4.2,0.7,5.6-2.1c1.9-3.6,2.6-7.5,2.1-11.6c-0.8-6.2-2.8-12.1-6-17.4
        C306.5,316.1,304.7,313.9,302.6,311.6z M187.7,255.6c0.1,0,0.2-0.1,0.2-0.1c0.1-0.4,0.2-0.8,0.2-1.1c0.3-2.9,0.8-5.9,1-8.8
        c0.3-3.2,0.2-6.3-0.3-9.5c-0.5-3.2-1.6-6.1-2.8-9.1c-0.8-2-1.9-3.8-3.5-5.2c-0.7-0.6-1.5-1.2-2.4-1.6c-1.7-0.8-3.1-0.5-4.5,0.8
        c-1.1,1.1-1.7,2.5-2.1,4c-0.8,3.5-0.4,6.9,1,10.1c1.2,2.7,2.6,5.4,4.2,7.9c2.7,4.2,5.6,8.2,8.5,12.3
        C187.6,255.3,187.6,255.4,187.7,255.6z M149.5,279.2c0.1,0,0.2,0,0.3,0c2.8-2.5,5.4-5.3,7.2-8.7c0.5-1.1,1-2.2,1.4-3.4
        c1.1-3.6,1.1-7.3-0.1-10.9c-0.5-1.5-1.4-2.8-2.6-3.8c-0.8-0.7-1-0.7-1.8,0.1c-0.9,0.9-1.6,2.1-2.1,3.2c-1.2,2.8-1.8,5.7-2.2,8.6
        c-0.5,3.7-0.5,7.5-0.4,11.3C149.2,276.9,149.4,278.1,149.5,279.2z M285.4,282.9c0-0.8-0.1-1.4-0.1-2c-0.2-3.5-0.7-6.9-1.9-10.2
        c-0.6-1.9-1.4-3.6-2.7-5.2c-0.8-1-1.7-1.8-2.9-2.1c-1-0.3-1.6-0.1-2.2,0.8c-0.9,1.3-1.2,2.7-0.9,4.2c0.5,3,1.8,5.6,3.7,7.9
        c1.9,2.3,4.1,4.3,6.4,6.2C284.9,282.6,285.1,282.7,285.4,282.9z"/>
                        <path class="st2" d="M335,230c-1.5,1.1-3,2.2-4.3,3.4c-2.2,2-4.4,4.1-6.5,6.2c-0.7,0.7-1.3,1.6-1.9,2.4c-0.7,1-1.2,2-1.4,3.2
        c-0.3,1.5-1.6,2.4-3,2.2c-1.4-0.3-2.4-1.6-2.2-3.1c0.1-0.9,0.4-1.7,0.8-2.5c0.9-2.2,2.3-4,3.9-5.8c3.5-3.8,7.4-7.2,11.7-10.2
        c4-2.8,8-5.6,12-8.4c3.8-2.7,7.4-5.6,10.6-9c4.7-4.9,8.3-10.5,10.6-16.8c0.8-2.1,1.3-4.3,1.2-6.6c-0.1-1.4-0.3-2.8-0.9-4.1
        c-1.2-2.5-3.4-3.4-6-2.6c-1.4,0.4-2.6,1.1-3.7,2c-0.6,0.4-1.2,0.9-1.8,1.3c-1.2,0.7-2.6,0.4-3.4-0.7c-0.8-1-0.8-2.5,0.2-3.4
        c2.6-2.3,5.4-4.2,8.9-4.6c4.9-0.7,9.1,1.9,10.9,6.7c1.3,3.4,1.4,6.9,0.6,10.5c-0.9,4.2-2.7,7.9-4.8,11.6
        c-3.6,6.2-8.3,11.4-13.8,15.9c-3.7,3.1-7.7,5.8-11.6,8.5c-0.4,0.3-0.8,0.6-1.2,0.9c0.1,0.4,0.2,0.8,0.3,1.3
        c1.3,4.2,2.4,8.5,3,12.8c0.7,4.9,0.8,9.8-0.6,14.6c-1,3.5-2.6,6.5-5.5,8.8c-4.2,3.3-9,4-14.1,2.6c-5-1.4-9.2-4.3-12.6-8.2
        c-5.2-5.9-8.6-12.8-10-20.6c-0.8-4.4-0.8-8.7,0.8-13c2.6-7,7.5-10.8,14.8-11.8c0.1,0,0.1,0,0.4-0.1c-0.3-0.3-0.5-0.6-0.8-0.8
        c-2.1-1.9-3.9-4-5.3-6.5c-1.7-2.9-2.6-6-2.5-9.4c0.2-7.4,6.6-12.8,14-11.9c5.1,0.6,11,4.8,11.8,12c0.2,1.7,0.4,3.4,0,5.1
        c-0.2,0.6-0.4,1.3-0.7,1.9c-0.4,0.7-1.1,1.1-1.9,1.2c-1.1,0.1-2-0.5-1.8-1.8c0.2-2.3,0-4.6-0.7-6.9c-0.8-2.6-2.2-4.7-4.5-6.1
        c-0.5-0.3-1-0.5-1.5-0.7c-2.4-0.9-4.4-0.2-5.9,1.9c-1.5,2.2-1.8,4.7-1.5,7.3c0.3,2.3,1.1,4.3,2.3,6.3c1.6,2.7,3.7,5,5.9,7.1
        c0.5,0.5,1,0.9,1.4,1.4c0.8,1.1,0.7,2.2-0.4,3.1c-1,0.8-2.1,1-3.3,1.1c-1.5,0.1-2.9,0.2-4.4,0.4c-2.5,0.4-4.4,1.8-5.6,4
        c-1.8,3.2-2.4,6.6-2.1,10.2c0.6,7,2.8,13.6,6.9,19.4c2.4,3.5,5.4,6.4,9.1,8.4c1.8,1,3.6,1.6,5.7,1.6c2.2,0,3.9-0.9,5.1-2.8
        c1-1.7,1.6-3.5,2-5.4c0.7-3.1,0.6-6.2,0.4-9.4c-0.3-4.6-1.6-9-2.4-13.5C335.2,230.3,335.1,230.2,335,230z"/>
                    </g>
                    <path class="st9" d="M81.7,200.7c-0.1-2.5-0.2-4.9-0.2-7.3c0-2.6,0.4-5.1,1.3-7.5c0.6-1.8,1.4-3.4,2.8-4.7c1.4-1.4,3-2.1,5-1.6
        c1.2,0.3,2.2,1,3,2c1.2,1.3,1.9,2.8,2.5,4.4c1.1,3,1.4,6.1,1.6,9.2c0.3,5.3-0.5,10.5-1.7,15.7c-0.3,1.2-0.6,2.4-0.8,3.7
        c-0.5,2.2-1.8,3.5-4,4.1c-2.8,0.7-5.6,0.8-8.5,0.3c-7-1-13.1-3.8-18-9c-1.4-1.5-2.6-3.1-3.5-4.9c-0.7-1.4-1.1-2.9-0.9-4.5
        c0.2-1.5,0.9-2.6,2.1-3.5c1.4-1.1,3.1-1.6,4.8-1.7c3.1-0.2,6.1,0.5,8.9,1.9c1.6,0.8,3.1,1.9,4.7,2.9
        C81.2,200.2,81.4,200.4,81.7,200.7z"/>
                </g>
            </svg>

        )
    }
}

export default CoffeeCup;
