import React from 'react';
import QRCode from 'qrcode.react';
import './LnInvoice.scss';
import CoffeeCup from './CoffeeCup';

class LnInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceUri: null,
            timeoutId: null,
            chargeId: null,
            apiKey: '6420f682-2452-4c29-8963-753469262f71',
            coffeePriceGbp: 0.30,
            isInvoicePaid: false,
            invoiceDescription: 'Gimmie some coffee!',
        }

        this.createCoffeeInvoice = this.createCoffeeInvoice.bind(this);
    }

    render() {
        document.title = "Buy some coffee!";
        let invoiceUri = this.state.invoiceUri;

        return (
            <div id="invoice_qr_code">
                <p>Lightning Invoice for &pound;{this.state.coffeePriceGbp.toFixed(2)}</p>
                {(invoiceUri && !this.state.isInvoicePaid) &&
                <React.Fragment>
                    <QRCode value={invoiceUri} size={500}/>
                    <a href={invoiceUri}>Pay now</a>
                    <div id="status">
                        <p>Status: Awaiting payment.</p>
                    </div>
                </React.Fragment>
                }
                {(this.state.isInvoicePaid) &&
                <React.Fragment>
                    <CoffeeCup/>
                    <p>Invoice paid. Enjoy your coffee :)</p>
                    <button onClick={this.createCoffeeInvoice}>Pay for more coffee.</button>
                </React.Fragment>
                }
            </div>
        );
    }

    componentDidMount() {
        this.createCoffeeInvoice();
    }

    createCoffeeInvoice() {
        clearTimeout(this.state.timeoutId);
        this.setState({
            isInvoicePaid: false,
            invoiceUri: null,
        });
        // Get the opennode payment QR code
        (async () => {
            const rawResponse = await fetch('https://api.opennode.co/v1/charges', {
                method: 'POST',
                headers: {
                    'Authorization': this.state.apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'amount': this.state.coffeePriceGbp,
                    'currency': 'GBP',
                    'description': this.state.invoiceDescription
                })
            });
            const content = await rawResponse.json();
            if (content.success === false) {
                clearTimeout(this.state.timeoutId);
            }
            this.setState({
                invoiceUri: content.data.uri,
                chargeId: content.data.id,
            })
            // Now check whether the invoice has been paid....
            this.checkInvoiceStatus();
        })();
    }

    checkInvoiceStatus() {
        // Don't check the status on the first run, i.e. only check whenthere is a timeoutId set
        if (this.state.timeoutId) {

            (async () => {
                const rawResponse = await fetch('https://api.opennode.co/v1/charge/' + this.state.chargeId, {
                    method: 'GET',
                    headers: {
                        'Authorization': this.state.apiKey,
                        'Content-Type': 'application/json'
                    },
                });
                const content = await rawResponse.json();
                if (content.success === false) {
                    clearTimeout(this.state.timeoutId);
                }
                if (content.data.status === 'paid') {
                    this.setState({
                        isInvoicePaid: true
                    });
                    clearTimeout(this.state.timeoutId);
                }
            })();
        }

        this.setState({
            timeoutId:
                setTimeout(function () {
                    this.checkInvoiceStatus()
                }.bind(this), 2000)
        })
    }

}

export default LnInvoice;
