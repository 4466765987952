import React from 'react';
import './App.css';
import LnInvoice from './LnInvoice';

function App() {

    return (
        <section>
            <LnInvoice/>
        </section>
    );

}

export default App;
